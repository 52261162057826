<template>
  <div>

    <!-- <div class="orderList-view view pa24">
    <div>
      <el-button type="primary" @click="addVersion">添加版本+</el-button>
    </div>
    <commonTable :tableData="tableData"
                 @handleSizeChange="handleSizeChange"
                 @handleCurrentChange="handleCurrentChange"
                 :currentPage="pageNum"
                 :total="total"
                 ref="commonTable"
                 >
      <template v-slot:table>
        <el-table-column prop="version" width="100" align="center" label="版本号">
          <template slot-scope="scope">
            {{scope.row.version}}
          </template>
        </el-table-column>
        <el-table-column prop="title" align="center" label="更新内容">
          <template slot-scope="scope">
            {{scope.row.content}}
          </template>
        </el-table-column>
        <el-table-column prop="title" align="center" label="操作">
          <template slot-scope="scope">
            <el-button type="text" @click="editVer(scope.row)">修改</el-button>
          </template>
        </el-table-column>
      </template>
    </commonTable>

    <AddVerDialog ref="addVerDialog"></AddVerDialog>
    </div> -->

    <div class="page-changelog content">
      <div class="heading">
        <button type="button" class="el-button fr el-button--default">
        </button> 更新日志 
      </div>
      <ul class="timeline">
        <!--一个版本开始-->
        <li>
          <h3>2.0.5</h3>
          <p><em>2023-04-07</em></p>
          <h4 id="bug-xiu-fu"> 新特性</h4>
          <ul>
            <li>
              <!-- Descriptions  -->
            <ul>
              <li>新增更新日志菜单</li>
            </ul>
            </li>
            <!-- <li> 
            <ul>
              <li>修复 slot 显示问题 (<a href="https://github.com/ElemeFE/element/issues/22375" target="_blank">#22375</a> by <a href="https://github.com/webvs2" target="_blank">@webvs2</a>)</li>
            </ul>
            </li>

            <li>Chore 
            <ul>
              <li>修复 web-type 文件丢失问题 (<a href="https://github.com/ElemeFE/element/issues/22271" target="_blank">#22271</a> by <a href="https://github.com/loosheng" target="_blank">@loosheng</a>)</li>
            </ul>
            </li> -->
          </ul>

        <h4 id="you-hua"> Bug修复</h4>
        <ul>
          <li> 
            <!-- Descriptions -->
          <ul>
            <li>服务到期提示优化</li>
          </ul>
          </li>

        </ul>
        </li>

      <!--一个版本结束-->

      <!--一个版本开始-->
        <li>
          <h3>2.0.4</h3>
          <p><em>2023-04-05</em></p>
          <h4 id="bug-xiu-fu"> 新特性</h4>
          <ul>
            <li>
              <!-- Descriptions  -->
            <ul>
              <li>模版管理，新增页面类别、图片管理菜单</li>
              <li>模版管理，数据模版增加一键复制功能</li>
            </ul>
            </li>
            <!-- <li> 
            <ul>
              <li>修复 slot 显示问题 (<a href="https://github.com/ElemeFE/element/issues/22375" target="_blank">#22375</a> by <a href="https://github.com/webvs2" target="_blank">@webvs2</a>)</li>
            </ul>
            </li>

            <li>Chore 
            <ul>
              <li>修复 web-type 文件丢失问题 (<a href="https://github.com/ElemeFE/element/issues/22271" target="_blank">#22271</a> by <a href="https://github.com/loosheng" target="_blank">@loosheng</a>)</li>
            </ul>
            </li> -->
          </ul>

        <h4 id="you-hua"> 优化</h4>
        <ul>
          <li> 
            <!-- Descriptions -->
          <ul>
            <li>模版上传图片，增加分类筛选、设置上传图片类别</li>
            <li>模版管理，页面列表，支持批量分类</li>
            <li>保存页面支持设置页面分类</li>
          </ul>
          </li>

        </ul>
        </li>

      <!--一个版本结束-->

      <!--一个版本开始-->
        <li>
          <h3>2.0.3</h3>
          <p><em>2023-04-01</em></p>
          <h4 id="bug-xiu-fu"> 新特性</h4>
          <ul>
            <li>
              <!-- Descriptions  -->
            <ul>
              <li>任意贴增加设备铭牌码</li>
            </ul>
            </li>
            <!-- <li> 
            <ul>
              <li>修复 slot 显示问题 (<a href="https://github.com/ElemeFE/element/issues/22375" target="_blank">#22375</a> by <a href="https://github.com/webvs2" target="_blank">@webvs2</a>)</li>
            </ul>
            </li>

            <li>Chore 
            <ul>
              <li>修复 web-type 文件丢失问题 (<a href="https://github.com/ElemeFE/element/issues/22271" target="_blank">#22271</a> by <a href="https://github.com/loosheng" target="_blank">@loosheng</a>)</li>
            </ul>
            </li> -->
          </ul>
        
        </li>

      <!--一个版本结束-->


       <!--一个版本开始-->
        <li>
          <h3>2.0.2</h3>
          <p><em>2023-03-20</em></p>
          <h4 id="bug-xiu-fu"> 优化</h4>
          <ul>
            <li>
              <!-- Descriptions  -->
            <ul>
              <li>任意贴自定义视频组件，支持上传水印</li>
              <li>任意贴增加文件上传、预览自定义组件</li>
              <li>任意贴优化富文本编辑器自定义组件</li>
            </ul>
            </li>
            <!-- <li> 
            <ul>
              <li>修复 slot 显示问题 (<a href="https://github.com/ElemeFE/element/issues/22375" target="_blank">#22375</a> by <a href="https://github.com/webvs2" target="_blank">@webvs2</a>)</li>
            </ul>
            </li>

            <li>Chore 
            <ul>
              <li>修复 web-type 文件丢失问题 (<a href="https://github.com/ElemeFE/element/issues/22271" target="_blank">#22271</a> by <a href="https://github.com/loosheng" target="_blank">@loosheng</a>)</li>
            </ul>
            </li> -->
          </ul>
        
        </li>

      <!--一个版本结束-->


      <!--一个版本开始-->
        <li>
          <h3>2.0.1</h3>
          <p><em>2023-03-10</em></p>
          <h4 id="bug-xiu-fu"> 新特性</h4>
          <ul>
            <li>
              <!-- Descriptions  -->
            <ul>
              <li>订单管理，增加评价管理</li>
              <li>订单管理，增加退货地址管理</li>
            </ul>
            </li>
            <!-- <li> 
            <ul>
              <li>修复 slot 显示问题 (<a href="https://github.com/ElemeFE/element/issues/22375" target="_blank">#22375</a> by <a href="https://github.com/webvs2" target="_blank">@webvs2</a>)</li>
            </ul>
            </li>

            <li>Chore 
            <ul>
              <li>修复 web-type 文件丢失问题 (<a href="https://github.com/ElemeFE/element/issues/22271" target="_blank">#22271</a> by <a href="https://github.com/loosheng" target="_blank">@loosheng</a>)</li>
            </ul>
            </li> -->
          </ul>
        
        </li>

      <!--一个版本结束-->






      
      </ul>
  </div>
</div>  
</template>

<script>
// import commonTable from "@/components/common/commonTable";
import { fetchVerData } from "@/api/customPage"
// import AddVerDialog from './components/addVerDialog'
export default {
  name: "customlist",
  components: {
    // commonTable,
    // AddVerDialog,
  },
  data() {
    let token = localStorage.getItem("token")
    return {
      tableData: [],
      pageNum: 1,
      pageSize: 10,
      currentPage: 1,
      total: 0,
    };
  },
  created() {
    // this.getTableData()
  },
  watch:{

  },
  filters: {
  },
  props:{
    
  },
  methods: {
    // 
    handleSizeChange(num) {
      this.pageSize = num;
      this.pageNum = 1;
      this.currentPage = 1;
      this.getTableData();
    },
    //当前页变更
    handleCurrentChange(num) {
      this.pageNum = num;
      this.getTableData();
    },
    // 获取版本号
    getTableData() {
       let params = {
         pageNum: 1,
         pageSize: 10,
       }
       fetchVerData(params).then(res => {
         console.log('res', res)
         this.total = res.data.total
         this.tableData = res.data.list
       })
    },
    // 添加版本
    addVersion() {
      this.$refs.addVerDialog.openDialog('add')
    },
    editVer(data) {
      this.$refs.addVerDialog.openDialog('edit',data)
    },
    refresh() {
      this.pageNum = 1
      this.getTableData()
    }
  },
};
</script>

<style scoped lang='scss'>
.lotSpan{
  // border: 1px solid #ccc;
  padding: 0 10px;
  cursor: pointer;
  width: auto;
  line-height: 30px;
  text-decoration: underline;
}
.disflex{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.gjBtn{
  height: 33px;
  padding: 5px;
  margin: 0 0 0 10px;
}
.linkIcon{
  width: 20px;
  height: 20px;
  display: inline;
  vertical-align: middle;
}
.coverImg{
  width: 60px;
}
/deep/ .el-upload--text{
  width: inherit;
  height: auto;
  border: none;
}
.el-upload__tip{
  color: #51CDCB;
}

.page-changelog {
    padding-bottom: 100px
}

.page-changelog .fr {
    float: right;
    padding: 0
}

.page-changelog .fr.el-button {
    transform: translateY(-3px)
}

.page-changelog .fr a {
    display: block;
    padding: 10px 15px;
    color: #333
}

.page-changelog .fr:hover a {
    color: #409eff
}

.page-changelog .heading {
    font-size: 24px;
    margin-bottom: 30px;
    color: #333
}

.page-changelog .timeline {
    padding: 0 0 10px;
    position: relative;
    color: #5e6d82
}

.page-changelog .timeline>li {
    position: relative;
    padding-bottom: 15px;
    list-style: none;
    line-height: 1.8;
    border: 1px solid #ddd;
    border-radius: 4px;
    background: #fff;
}

.page-changelog .timeline>li:not(:last-child) {
    margin-bottom: 50px
}

.page-changelog .timeline ul {
    padding: 0px 30px 0px
}

.page-changelog .timeline ul ul {
    padding: 5px 0 0 27px
}

.page-changelog .timeline ul ul li {
    padding-left: 0;
    color: #555;
    word-break: normal
}

.page-changelog .timeline ul ul li:before {
    content: "";
    circle: 4px #fff;
    border: 1px solid #333;
    margin-right: -12px;
    display: inline-block;
    vertical-align: middle
}

.page-changelog .timeline li li {
    font-size: 16px;
    list-style: none;
    padding-left: 20px;
    padding-bottom: 5px;
    color: #333;
    word-break: break-all
}

.page-changelog .timeline li li:before {
    content: "";
    circle: 6px #333;
    transform: translateX(-20px);
    display: inline-block;
    vertical-align: middle
}

.page-changelog .timeline i {
    padding: 0 20px;
    display: inline-block
}

.page-changelog .timeline h3 {
    margin: 0;
    padding: 15px 30px;
    border-bottom: 1px solid #ddd;
    font-size: 20px;
    color: #333;
    font-weight: 700
}

.page-changelog .timeline h3 a {
    opacity: 1;
    font-size: 20px;
    float: none;
    margin-left: 0;
    color: #333
}

.page-changelog .timeline h4 {
    margin: 0 0 -10px;
    font-size: 18px;
    padding-left: 54px;
    padding-top: 10px;
    font-weight: 700
}

.page-changelog .timeline p {
    margin: 0
}

.page-changelog .timeline em {
    position: absolute;
    right: 30px;
    font-style: normal;
    top: 23px;
    font-size: 16px;
    color: #666
}

.page-component__scroll {
    height: calc(100% - 80px);
    margin-top: 80px
}

.page-component__scroll>.el-scrollbar__wrap {
    overflow-x: auto
}

.page-component {
    box-sizing: border-box;
    height: 100%
}

.page-component.page-container {
    padding: 0
}

.page-component .page-component__nav {
    width: 240px;
    position: fixed;
    top: 0;
    bottom: 0;
    margin-top: 80px;
    transition: padding-top .3s
}

.page-component .page-component__nav>.el-scrollbar__wrap {
    height: 100%;
    overflow-x: auto
}

.page-component .page-component__nav.is-extended {
    padding-top: 0
}

.page-component .side-nav {
    height: 100%;
    padding-top: 50px;
    padding-bottom: 50px;
    padding-right: 0
}

.page-component .side-nav>ul {
    padding-bottom: 50px
}

.page-component .page-component__content {
    padding-left: 270px;
    padding-bottom: 100px;
    box-sizing: border-box
}

.page-component .content {
    padding-top: 50px
}

.page-component .content>h3 {
    margin: 55px 0 20px
}

.page-component .content>table {
    border-collapse: collapse;
    width: 100%;
    background-color: #fff;
    font-size: 14px;
    margin-bottom: 45px;
    line-height: 1.5em
}

.page-component .content>table strong {
    font-weight: 400
}

.page-component .content>table td,.page-component .content>table th {
    border-bottom: 1px solid #dcdfe6;
    padding: 15px;
    max-width: 250px
}

.page-component .content>table th {
    text-align: left;
    white-space: nowrap;
    color: #909399;
    font-weight: 400
}

.page-component .content>table td {
    color: #606266
}

.page-component .content>table td:first-child,.page-component .content>table th:first-child {
    padding-left: 10px
}

.page-component .content>ul:not(.timeline) {
    margin: 10px 0;
    padding: 0 0 0 20px;
    font-size: 14px;
    color: #5e6d82;
    line-height: 2em
}

@media (max-width: 768px) {
    .page-component .page-component__nav {
        width:100%;
        position: static;
        margin-top: 0
    }

    .page-component .side-nav {
        padding-top: 0;
        padding-left: 50px
    }

    .page-component .page-component__content {
        padding-left: 10px;
        padding-right: 10px
    }

    .page-component .content {
        padding-top: 0
    }

    .page-component .content>table {
        overflow: auto;
        display: block
    }
}

.cards[data-v-7aed89bc] {
    margin: 30px 0 70px
}

.card[data-v-7aed89bc] {
    background: #fbfcfd;
    height: 204px;
    text-align: center
}

.card img[data-v-7aed89bc] {
    margin: 40px auto 25px;
    width: 80px;
    height: 80px
}

.card h4[data-v-7aed89bc] {
    font-size: 18px;
    color: #1f2d3d;
    font-weight: 400;
    margin: 0
}

.card span[data-v-7aed89bc] {
    font-size: 14px;
    color: #99a9bf
}

.page-guide {
    padding: 55px 30px 95px;
    box-sizing: border-box
}

.page-guide .content {
    padding-left: 25px;
    margin-left: -1px
}

.page-guide .content h2 {
    margin-bottom: 10px
}

.page-guide .content h3 {
    font-size: 22px;
    font-weight: 400;
    margin: 0 0 30px;
    color: #1f2d3d
}

.page-guide .content p {
    line-height: 1.6;
    font-size: 14px;
    color: #5e6d82
}

.page-guide .content ul {
    margin-bottom: 50px;
    padding-left: 0
}

.page-guide .content li {
    font-size: 14px;
    margin-bottom: 10px;
    color: #99a9bf;
    list-style: none
}

.page-guide .content li:before {
    content: "";
    display: inline-block;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    vertical-align: middle;
    background-color: #5e6d82;
    margin-right: 5px
}

.page-guide .content li strong {
    color: #5e6d82;
    font-weight: 400
}

@media (max-width: 768px) {
    .page-guide .content {
        padding-left:0
    }
}

.banner[data-v-706538fd] {
    text-align: center
}

.banner-desc[data-v-706538fd] {
    padding-top: 50px
}

.banner-desc h1[data-v-706538fd] {
    font-size: 34px;
    margin: 0;
    line-height: 48px;
    color: #555
}

.banner-desc p[data-v-706538fd] {
    font-size: 18px;
    line-height: 28px;
    color: #888;
    margin: 10px 0 5px
}

.sponsors[data-v-706538fd] {
    display: flex;
    justify-content: center
}

.sponsor[data-v-706538fd] {
    margin: 0 20px 50px;
    display: inline-flex;
    width: 300px;
    height: 100px;
    justify-content: center
}

.sponsor img[data-v-706538fd] {
    margin-right: 20px
}

.sponsor div[data-v-706538fd] {
    display: flex;
    flex-direction: column;
    justify-content: center
}

.sponsor p[data-v-706538fd] {
    margin: 0;
    line-height: 1.8;
    color: #999;
    font-size: 14px
}

.jumbotron[data-v-706538fd] {
    width: 890px;
    margin: 30px auto;
    position: relative
}

.jumbotron img[data-v-706538fd] {
    width: 100%
}

.jumbotron .jumbotron-red[data-v-706538fd] {
    transition: height .1s;
    background: #fff;
    position: absolute;
    left: 0;
    top: 0;
    overflow: hidden
}

.cards[data-v-706538fd] {
    margin: 0 auto 110px;
    width: 1140px
}

.cards .container[data-v-706538fd] {
    padding: 0;
    margin: 0 -11px;
    width: auto
}

.cards .container[data-v-706538fd]:after,.cards .container[data-v-706538fd]:before {
    display: table;
    content: ""
}

.cards .container[data-v-706538fd]:after {
    clear: both
}

.cards li[data-v-706538fd] {
    width: 25%;
    padding: 0 19px;
    box-sizing: border-box;
    float: left;
    list-style: none
}

.cards img[data-v-706538fd] {
    width: 160px;
    height: 120px
}

.card[data-v-706538fd] {
    height: 430px;
    width: 100%;
    background: #fff;
    border: 1px solid #eaeefb;
    border-radius: 5px;
    box-sizing: border-box;
    text-align: center;
    position: relative;
    transition: all .3s ease-in-out;
    bottom: 0
}

.card img[data-v-706538fd] {
    margin: 66px auto 60px
}

.card h3[data-v-706538fd] {
    margin: 0;
    font-size: 18px;
    color: #1f2f3d;
    font-weight: 400
}

.card p[data-v-706538fd] {
    font-size: 14px;
    color: #99a9bf;
    padding: 0 25px;
    line-height: 20px
}

.card a[data-v-706538fd] {
    height: 53px;
    line-height: 52px;
    font-size: 14px;
    color: #409eff;
    text-align: center;
    border: 0;
    border-top: 1px solid #eaeefb;
    padding: 0;
    cursor: pointer;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: #fff;
    border-radius: 0 0 5px 5px;
    transition: all .3s;
    text-decoration: none;
    display: block
}

.card a[data-v-706538fd]:hover {
    color: #fff;
    background: #409eff
}

.card[data-v-706538fd]:hover {
    bottom: 6px;
    box-shadow: 0 6px 18px 0 rgba(232,237,250,.5)
}

@media (max-width: 1140px) {
    .cards[data-v-706538fd] {
        width:100%
    }

    .cards .container[data-v-706538fd] {
        width: 100%;
        margin: 0
    }

    .banner .container[data-v-706538fd] {
        width: 100%;
        box-sizing: border-box
    }

    .banner img[data-v-706538fd] {
        right: 0
    }
}

@media (max-width: 1000px) {
    .banner .container img[data-v-706538fd],.jumbotron[data-v-706538fd] {
        display:none
    }
}

@media (max-width: 768px) {
    .cards li[data-v-706538fd] {
        width:80%;
        margin: 0 auto 20px;
        float: none
    }

    .cards .card[data-v-706538fd] {
        height: auto;
        padding-bottom: 54px
    }

    .banner-desc #line2[data-v-706538fd],.banner-stars[data-v-706538fd] {
        display: none
    }

    .banner-desc h2[data-v-706538fd] {
        font-size: 32px
    }

    .banner-desc p[data-v-706538fd] {
        width: auto
    }
}

.theme-intro-b[data-v-706538fd] {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 200
}

.theme-intro-b .intro-banner[data-v-706538fd] {
    position: absolute
}

.theme-intro-b img[data-v-706538fd] {
    width: 300px
}

.theme-intro-b .title[data-v-706538fd] {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    color: #fff;
    text-align: center;
    font-weight: 700;
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center
}

.theme-intro-b .title p[data-v-706538fd] {
    padding: 0;
    margin: 10px 0
}

.theme-intro-a[data-v-706538fd] {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 200
}

.theme-intro-a .mask[data-v-706538fd] {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: #000;
    opacity: .5
}

.theme-intro-a .intro-banner[data-v-706538fd] {
    top: 50%;
    left: 50%;
    position: fixed;
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    box-sizing: border-box;
    text-align: center;
    z-index: 100
}

.theme-intro-a .intro-banner img[data-v-706538fd] {
    width: 100%
}

.theme-intro-a .intro-banner .intro-text[data-v-706538fd] {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0
}

.theme-intro-a .intro-banner .intro-text p[data-v-706538fd] {
    padding: 0;
    margin: 0;
    font-size: 48px;
    font-weight: 700;
    color: #fff
}

h3[data-v-aadaaa32] {
    margin-bottom: 15px
}

.block[data-v-aadaaa32] {
    margin-bottom: 55px
}

p[data-v-aadaaa32] {
    margin: 0 0 15px
}

.nav-demos p[data-v-aadaaa32] {
    margin-bottom: 50px
}

.nav-demos h5[data-v-aadaaa32] {
    margin: 0
}

.nav-demos img[data-v-aadaaa32] {
    padding: 15px;
    background-color: #f9fafc;
    width: 100%;
    margin-bottom: 15px;
    cursor: pointer
}

.dialog-img[data-v-aadaaa32] {
    position: fixed;
    overflow: auto;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1000;
    -webkit-overflow-scrolling: touch;
    outline: 0
}

.dialog-img .imgWrap[data-v-aadaaa32] {
    margin: 0 auto;
    position: relative;
    top: 100px;
    padding-bottom: 50px
}

.dialog-img img[data-v-aadaaa32] {
    display: block;
    width: 100%
}

.mask[data-v-aadaaa32] {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: #373737;
    background-color: rgba(55,55,55,.6);
    height: 100%;
    z-index: 1000
}

.zoom-enter-active[data-v-aadaaa32],.zoom-leave-active[data-v-aadaaa32] {
    transition: transform .3s cubic-bezier(.78,.14,.15,.86),opacity .3s cubic-bezier(.78,.14,.15,.86)
}

.zoom-enter[data-v-aadaaa32],.zoom-leave-active[data-v-aadaaa32] {
    transform: scale(.3);
    opacity: 0
}

.fade-enter-active[data-v-aadaaa32],.fade-leave-active[data-v-aadaaa32] {
    transition: opacity .3s cubic-bezier(.78,.14,.15,.86)
}

.fade-enter[data-v-aadaaa32],.fade-leave-active[data-v-aadaaa32] {
    opacity: 0
}

.page-resource[data-v-c8214268] {
    padding-top: 55px;
    box-sizing: border-box
}

.page-resource .resource-placeholder[data-v-c8214268] {
    margin: 50px auto 100px;
    text-align: center
}

.page-resource .resource-placeholder img[data-v-c8214268] {
    width: 150px
}

.page-resource .resource-placeholder h4[data-v-c8214268] {
    margin: 20px 0 16px;
    font-size: 16px;
    color: #1f2f3d;
    line-height: 1
}

.page-resource .resource-placeholder p[data-v-c8214268] {
    margin: 0;
    font-size: 14px;
    color: #99a9bf;
    line-height: 1
}

.cards[data-v-c8214268] {
    margin: 35px auto 110px
}

.cards .container[data-v-c8214268] {
    padding: 0;
    margin: 0 -11px;
    width: auto
}

.cards .container[data-v-c8214268]:after,.cards .container[data-v-c8214268]:before {
    display: table;
    content: ""
}

.cards .container[data-v-c8214268]:after {
    clear: both
}

.cards li[data-v-c8214268] {
    width: 33.33333%;
    padding: 0 11px;
    box-sizing: border-box;
    float: left;
    list-style: none
}

h2[data-v-c8214268] {
    font-size: 28px;
    margin: 0
}

p[data-v-c8214268] {
    font-size: 14px;
    color: #5e6d82
}

.card[data-v-c8214268] {
    height: 394px;
    width: 100%;
    background: #fff;
    border: 1px solid #eaeefb;
    border-radius: 5px;
    box-sizing: border-box;
    text-align: center;
    position: relative;
    transition: bottom .3s;
    bottom: 0
}

.card img[data-v-c8214268] {
    margin: 75px auto 35px;
    height: 87px
}

.card h3[data-v-c8214268] {
    margin: 0 0 10px;
    font-size: 18px;
    color: #1f2f3d;
    font-weight: 400;
    height: 22px
}

.card p[data-v-c8214268] {
    font-size: 14px;
    color: #99a9bf;
    padding: 0 30px;
    margin: 0;
    word-break: break-all;
    line-height: 1.8
}

.card a[data-v-c8214268] {
    height: 42px;
    width: 190px;
    display: inline-block;
    line-height: 42px;
    font-size: 14px;
    background-color: #409eff;
    color: #fff;
    text-align: center;
    border: 0;
    padding: 0;
    cursor: pointer;
    border-radius: 2px;
    transition: all .3s;
    text-decoration: none;
    margin-top: 20px
}

@media (max-width: 850px) {
    .cards li[data-v-c8214268] {
        max-width:500px;
        float: none;
        margin: 10px auto 30px;
        width: 80%
    }

    .cards li .card[data-v-c8214268] {
        height: auto;
        padding-bottom: 20px
    }

    .cards h3[data-v-c8214268] {
        height: auto
    }
}

.page-container.page-theme-preview {
    padding-top: 30px
}

.page-container.page-theme-preview .display {
    width: 75%;
    display: inline-block;
    vertical-align: top
}

.page-container.page-theme-preview .display h3 {
    font-size: 28px;
    margin: 30px 0 0
}

.page-container.page-theme-preview .side {
    display: inline-block;
    width: 25%
}

.page-container.page-theme-preview .side .editor {
    overflow: hidden;
    background: #f5f7fa;
    border: 1px solid #ebeef5;
    border-radius: 5px;
    margin-bottom: 20px
}

.page-container.page-theme-preview .side .editor.fixed {
    position: fixed;
    width: 285px;
    box-sizing: border-box
}

.page-theme:last-child {
    margin-bottom: 55px
}

.page-theme h2 {
    font-size: 28px;
    line-height: 28px;
    margin: 0
}

.page-theme ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between
}

.page-theme .theme-card {
    display: inline-block;
    height: 150px;
    height: 16vw;
    max-height: 230px;
    flex: 0 0 24%;
    cursor: default;
    vertical-align: bottom
}

.page-theme .theme-section {
    margin-bottom: 20px
}

.page-theme .second-section {
    margin-top: 60px
}

</style>
